*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

@font-face {
  font-family: SF Pro Rounded;
  src: url("SF-Pro-Rounded-Semibold.c5aee88d.woff2") format("woff2"), url("SF-Pro-Rounded-Semibold.fdff7ac1.woff") format("woff");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: Druk Wide;
  src: url("DrukTextWide-Medium.0135f7a4.woff2") format("woff2"), url("DrukTextWide-Medium.62fe808d.woff") format("woff"), url("DrukTextWide-Medium.9c671cef.ttf") format("ttf");
  font-style: normal;
  font-weight: 500;
}

* {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

::selection {
  background: #f9bb004d;
}

body {
  color: #fff;
  background: #020202;
}

header {
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}

header img {
  object-fit: cover;
  object-position: bottom;
  width: 100vw;
  height: 1000px;
  opacity: 0;
}

@media (max-width: 1024px) {
  header img {
    height: 75vh;
    object-position: 25% center;
  }
}

header img:not(.initial) {
  transition: opacity 1.5s ease-out;
}

header img.loaded, header img.initial, header img.error {
  opacity: 1;
}

footer {
  flex-direction: column;
  align-items: center;
  padding: 80px 0;
  display: flex;
}

footer .copyright {
  color: #ffffff4d;
  font-size: 18px;
  font-weight: 500;
}

@media (max-width: 1024px) {
  footer .copyright {
    font-size: 14px;
  }
}

h1 {
  color: #fff;
  text-shadow: 0 4px 36px #000000b3;
  font-size: 44px;
  font-weight: bold;
  line-height: normal;
}

@media (max-width: 1024px) {
  h1 {
    font-size: 32px;
  }
}

h2 {
  opacity: .4;
  color: #fff;
  letter-spacing: -.4px;
  text-shadow: 0 2px 4px #00000080;
  font-size: 26px;
  font-weight: 500;
}

@media (max-width: 1024px) {
  h2 {
    font-size: 20px;
  }
}

p {
  opacity: .4;
  color: #fff;
  font-size: 20px;
  line-height: 1.6em;
}

@media (max-width: 1024px) {
  p {
    font-size: 16px;
  }
}

p a {
  color: #fff;
}

a {
  will-change: transform;
  transition: transform .15s cubic-bezier(.17, .67, .83, .67);
}

a img {
  max-width: unset;
}

@media (max-width: 1024px) {
  a img {
    width: 80%;
    height: auto;
    margin: auto;
  }
}

a:active {
  transform: scale(.95);
}

.appstore-link {
  margin-top: 32px;
}

.wrapper {
  max-width: 1200px;
  width: 90%;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(100px, auto);
  gap: 40px;
  margin: 0 auto;
  display: grid;
}

@media (max-width: 1024px) {
  .wrapper {
    gap: 20px;
  }
}

.full-width-container {
  position: relative;
}

.hero {
  -webkit-backdrop-filter: blur(34px) saturate(2);
  backdrop-filter: blur(34px) saturate(2);
  grid-column: 1 / 3;
}

.hero .copy {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 1024px) {
  .hero .copy {
    margin-bottom: 20px;
  }
}

.hero .copy .app-icon {
  margin-bottom: -35px;
}

@media (max-width: 1024px) {
  .hero .copy .app-icon {
    width: 160px;
    height: 160px;
  }
}

.full-width {
  height: 580px;
  grid-column: 1 / 3;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .full-width {
    height: unset;
    aspect-ratio: 1;
    background: #1b1b1b;
  }
}

.block {
  z-index: 10;
  background: #ffffff1a;
  border-radius: 40px;
  justify-content: center;
  padding: 100px;
  display: flex;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 29px 64px 40px #00000080;
}

@media (max-width: 1024px) {
  .block {
    border-radius: 28px;
    padding: 24px;
  }
}

.block.square {
  aspect-ratio: 1;
}

@media (max-width: 1024px) {
  .block.square {
    grid-column: 1 / 3;
  }
}

.block.hero {
  height: 800px;
  justify-content: space-around;
  margin-top: 150px;
}

@media (max-width: 1024px) {
  .block.hero {
    height: 750px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5%;
  }
}

.block .background {
  object-fit: cover;
  z-index: 5;
  min-height: 100%;
  object-position: center left;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.block .map {
  will-change: transform;
}

.block .icon {
  z-index: 10;
  position: absolute;
  top: calc(50% + 18px);
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

@media (max-width: 1024px) {
  .block .icon {
    transform: translate3d(-50%, -50%, 0)scale(.75);
  }
}

.block#document .lines {
  will-change: transform;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (max-width: 1024px) {
  .block#document .lines {
    transform: scale(.75);
  }
}

.block#document .lines .line1 {
  margin-left: -280px;
}

.block#document .lines .line3 {
  margin-left: 200px;
}

.block#document .lines img {
  max-width: unset;
}

.block.me {
  height: unset;
  flex-direction: row;
  justify-content: space-between;
  padding: 40px;
  display: flex;
}

@media (max-width: 1024px) {
  .block.me {
    aspect-ratio: unset;
    flex-direction: column;
    gap: 20px;
    padding: 24px;
  }
}

.block.me .avatar {
  min-height: 100%;
  aspect-ratio: 1 / 1;
  width: auto;
  border-radius: 50%;
}

@media (max-width: 1024px) {
  .block.me .avatar {
    display: none;
  }
}

.block.me .left {
  flex-direction: row;
  flex: 1;
  gap: 30px;
  display: flex;
}

.block.me .left .copy {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.block.me .right {
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-end;
  gap: 20px;
  display: flex;
}

@media (max-width: 1024px) {
  .block.me .right {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
  }

  .block.me .right a img {
    margin: 0;
  }
}

.block.me h3 {
  position: unset;
  margin-bottom: 8px;
  line-height: normal;
}

.block.me:before {
  content: unset;
}

.block h3 {
  text-align: center;
  z-index: 20;
  font-size: 32px;
  font-weight: 600;
  position: absolute;
  bottom: 42px;
}

@media (max-width: 1024px) {
  .block h3 {
    font-size: 20px;
    bottom: 24px;
  }
}

.block:before {
  content: "";
  height: 200px;
  z-index: 10;
  background-image: linear-gradient(#1a1a1a00 0%, #1a1a1a 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.block:after {
  content: "";
  z-index: 20;
  pointer-events: none;
  border-radius: 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: inset 0 0 0 1px #ffffff12;
}

@media (max-width: 1024px) {
  .block:after {
    border-radius: 28px;
  }
}

.shutter {
  width: 140px;
  height: 140px;
  background: linear-gradient(#fcd200 0%, #f7a900 100%);
  border-radius: 50%;
  margin: 150px;
  position: relative;
}

@media (color-gamut: p3) {
  .shutter {
    background: linear-gradient(#ffd500 0%, #fca900 100%);
    background: linear-gradient(color(display-p3 1 .835 0) 0%, color(display-p3 .969 .663 0) 100%);
  }
}

@media (max-width: 1024px) {
  .shutter {
    width: 80px;
    height: 80px;
    margin: 100px;
  }
}

@media (max-width: 480px) {
  .shutter {
    width: 50px;
    height: 50px;
    margin: 60px;
  }
}

.shutter:after {
  content: "";
  opacity: .3;
  border: 8px solid #f9bb00;
  border-radius: 50%;
  position: absolute;
  top: -20px;
  bottom: -20px;
  left: -20px;
  right: -20px;
}

@media (color-gamut: p3) {
  .shutter:after {
    border: 8px solid #fdbb00;
    border: 8px solid color(display-p3 .976 .733 0);
  }
}

@media (max-width: 1024px) {
  .shutter:after {
    border-width: 6px;
    top: -12px;
    bottom: -12px;
    left: -12px;
    right: -12px;
  }
}

@media (max-width: 480px) {
  .shutter:after {
    border-width: 4px;
    top: -8px;
    bottom: -8px;
    left: -8px;
    right: -8px;
  }
}

.shutter .dash {
  height: 14px;
  width: 44px;
  -webkit-user-select: none;
  user-select: none;
  background: linear-gradient(#fcd200 0%, #f7a900 100%);
  border-radius: 7px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@media (color-gamut: p3) {
  .shutter .dash {
    background: linear-gradient(#ffd500 0%, #fca900 100%);
    background: linear-gradient(color(display-p3 1 .835 0) 0%, color(display-p3 .969 .663 0) 100%);
  }
}

@media (max-width: 1024px) {
  .shutter .dash {
    height: 12px;
    width: 32px;
  }
}

@media (max-width: 480px) {
  .shutter .dash {
    height: 6px;
    width: 20px;
  }
}

.shutter .dash.left {
  left: -100px;
}

@media (max-width: 1024px) {
  .shutter .dash.left {
    left: -75px;
  }
}

@media (max-width: 480px) {
  .shutter .dash.left {
    left: -40px;
  }
}

.shutter .dash.right {
  right: -100px;
}

@media (max-width: 1024px) {
  .shutter .dash.right {
    right: -75px;
  }
}

@media (max-width: 480px) {
  .shutter .dash.right {
    right: -40px;
  }
}

.wheels {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.wheels .wheel {
  width: 750px;
  height: 750px;
  will-change: transform;
  background: #2e2d2f;
  border-radius: 50%;
  display: block;
}

@media (max-width: 1024px) {
  .wheels .wheel {
    height: 625px;
    width: 625px;
  }
}

@media (max-width: 480px) {
  .wheels .wheel {
    width: 500px;
    height: 500px;
  }
}

.wheels .wheel.right span {
  transform-origin: 100%;
  justify-content: flex-start;
  padding-left: 30px;
  left: 0;
}

@media (max-width: 1024px) {
  .wheels .wheel.right span {
    padding-left: 25px;
  }
}

@media (max-width: 480px) {
  .wheels .wheel.right span {
    padding-left: 20px;
  }
}

.wheels .wheel.right span:nth-child(2n+1) {
  transform: rotate(136deg);
}

@media (max-width: 1024px) {
  .wheels .wheel.right span:nth-child(2n+1) {
    transform: rotate(140deg);
  }
}

@media (max-width: 480px) {
  .wheels .wheel.right span:nth-child(2n+1) {
    transform: rotate(144deg);
  }
}

.wheels .wheel.right span:nth-child(2n+2) {
  transform: rotate(114deg);
}

@media (max-width: 1024px) {
  .wheels .wheel.right span:nth-child(2n+2) {
    transform: rotate(120deg);
  }
}

@media (max-width: 480px) {
  .wheels .wheel.right span:nth-child(2n+2) {
    transform: rotate(126deg);
  }
}

.wheels .wheel.right span:nth-child(2n+3) {
  transform: rotate(92deg);
}

@media (max-width: 1024px) {
  .wheels .wheel.right span:nth-child(2n+3) {
    transform: rotate(100deg);
  }
}

@media (max-width: 480px) {
  .wheels .wheel.right span:nth-child(2n+3) {
    transform: rotate(108deg);
  }
}

.wheels .wheel.right span:nth-child(2n+4) {
  transform: rotate(70deg);
}

@media (max-width: 1024px) {
  .wheels .wheel.right span:nth-child(2n+4) {
    transform: rotate(80deg);
  }
}

@media (max-width: 480px) {
  .wheels .wheel.right span:nth-child(2n+4) {
    transform: rotate(90deg);
  }
}

.wheels .wheel.right span:nth-child(2n+5) {
  transform: rotate(48deg);
}

@media (max-width: 1024px) {
  .wheels .wheel.right span:nth-child(2n+5) {
    transform: rotate(60deg);
  }
}

@media (max-width: 480px) {
  .wheels .wheel.right span:nth-child(2n+5) {
    transform: rotate(72deg);
  }
}

.wheels .wheel.right span:nth-child(2n+6) {
  transform: rotate(26deg);
}

@media (max-width: 1024px) {
  .wheels .wheel.right span:nth-child(2n+6) {
    transform: rotate(40deg);
  }
}

@media (max-width: 480px) {
  .wheels .wheel.right span:nth-child(2n+6) {
    transform: rotate(54deg);
  }
}

.wheels .wheel.right span:nth-child(2n+7) {
  transform: rotate(4deg);
}

@media (max-width: 1024px) {
  .wheels .wheel.right span:nth-child(2n+7) {
    transform: rotate(20deg);
  }
}

@media (max-width: 480px) {
  .wheels .wheel.right span:nth-child(2n+7) {
    transform: rotate(36deg);
  }
}

.wheels .wheel.right span:nth-child(2n+8) {
  transform: rotate(-18deg);
}

@media (max-width: 1024px) {
  .wheels .wheel.right span:nth-child(2n+8) {
    transform: rotate(0deg);
  }
}

@media (max-width: 480px) {
  .wheels .wheel.right span:nth-child(2n+8) {
    transform: rotate(18deg);
  }
}

.wheels .wheel.right span:nth-child(2n+9) {
  transform: rotate(-40deg);
}

@media (max-width: 1024px) {
  .wheels .wheel.right span:nth-child(2n+9) {
    transform: rotate(-20deg);
  }
}

@media (max-width: 480px) {
  .wheels .wheel.right span:nth-child(2n+9) {
    transform: rotate(0deg);
  }
}

.wheels .wheel span {
  text-align: right;
  transform-origin: 0;
  -webkit-user-select: none;
  user-select: none;
  width: 50%;
  height: 100%;
  opacity: .4;
  justify-content: flex-end;
  align-items: center;
  padding-right: 30px;
  font-family: ui-rounded, SF Pro Rounded;
  font-size: 50px;
  font-weight: 600;
  display: flex;
  position: absolute;
  left: 50%;
}

@media (max-width: 1024px) {
  .wheels .wheel span {
    padding-right: 25px;
    font-size: 40px;
  }
}

@media (max-width: 480px) {
  .wheels .wheel span {
    padding-right: 20px;
    font-size: 30px;
  }
}

.wheels .wheel span:nth-child(2n+1) {
  transform: rotate(0deg);
}

@media (max-width: 1024px) {
  .wheels .wheel span:nth-child(2n+1) {
    transform: rotate(0deg);
  }
}

@media (max-width: 480px) {
  .wheels .wheel span:nth-child(2n+1) {
    transform: rotate(0deg);
  }
}

.wheels .wheel span:nth-child(2n+2) {
  transform: rotate(22deg);
}

@media (max-width: 1024px) {
  .wheels .wheel span:nth-child(2n+2) {
    transform: rotate(20deg);
  }
}

@media (max-width: 480px) {
  .wheels .wheel span:nth-child(2n+2) {
    transform: rotate(18deg);
  }
}

.wheels .wheel span:nth-child(2n+3) {
  transform: rotate(44deg);
}

@media (max-width: 1024px) {
  .wheels .wheel span:nth-child(2n+3) {
    transform: rotate(40deg);
  }
}

@media (max-width: 480px) {
  .wheels .wheel span:nth-child(2n+3) {
    transform: rotate(36deg);
  }
}

.wheels .wheel span:nth-child(2n+4) {
  transform: rotate(66deg);
}

@media (max-width: 1024px) {
  .wheels .wheel span:nth-child(2n+4) {
    transform: rotate(60deg);
  }
}

@media (max-width: 480px) {
  .wheels .wheel span:nth-child(2n+4) {
    transform: rotate(54deg);
  }
}

.wheels .wheel span:nth-child(2n+5) {
  transform: rotate(88deg);
}

@media (max-width: 1024px) {
  .wheels .wheel span:nth-child(2n+5) {
    transform: rotate(80deg);
  }
}

@media (max-width: 480px) {
  .wheels .wheel span:nth-child(2n+5) {
    transform: rotate(72deg);
  }
}

.wheels .wheel span:nth-child(2n+6) {
  transform: rotate(110deg);
}

@media (max-width: 1024px) {
  .wheels .wheel span:nth-child(2n+6) {
    transform: rotate(100deg);
  }
}

@media (max-width: 480px) {
  .wheels .wheel span:nth-child(2n+6) {
    transform: rotate(90deg);
  }
}

.wheels .wheel span:nth-child(2n+7) {
  transform: rotate(132deg);
}

@media (max-width: 1024px) {
  .wheels .wheel span:nth-child(2n+7) {
    transform: rotate(120deg);
  }
}

@media (max-width: 480px) {
  .wheels .wheel span:nth-child(2n+7) {
    transform: rotate(108deg);
  }
}

.wheels .wheel span:nth-child(2n+8) {
  transform: rotate(154deg);
}

@media (max-width: 1024px) {
  .wheels .wheel span:nth-child(2n+8) {
    transform: rotate(140deg);
  }
}

@media (max-width: 480px) {
  .wheels .wheel span:nth-child(2n+8) {
    transform: rotate(126deg);
  }
}

.wheels .wheel span:nth-child(2n+9) {
  transform: rotate(176deg);
}

@media (max-width: 1024px) {
  .wheels .wheel span:nth-child(2n+9) {
    transform: rotate(160deg);
  }
}

@media (max-width: 480px) {
  .wheels .wheel span:nth-child(2n+9) {
    transform: rotate(144deg);
  }
}

/*# sourceMappingURL=index.1c3ff5dc.css.map */
