@import "reset.css";
@import "../fonts/sf-pro-rounded.css";
@import "../fonts/druk-wide.css";

$background: #020202;
$breakpoint-medium: 1024px;
$breakpoint-small: 480px;

* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

::selection {
  background: rgba(249, 187, 0, 0.3);
}

body {
  background: #020202;
  color: white;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;

  img {
    object-fit: cover;
    object-position: bottom;
    width: 100vw;
    height: 1000px;
    opacity: 0;

    @media (max-width: $breakpoint-medium) {
      height: 75vh;
      object-position: 25% center;
    }

    &:not(.initial) {
      transition: opacity 1.5s ease-out;
    }

    &.loaded,
    &.initial,
    &.error {
      opacity: 1;
    }
  }
}

footer {
  display: flex;
  padding: 80px 0;
  flex-direction: column;
  align-items: center;

  .copyright {
    font-weight: 500;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.3);

    @media (max-width: $breakpoint-medium) {
      font-size: 14px;
    }
  }
}

h1 {
  font-weight: bold;
  font-size: 44px;
  color: #ffffff;
  text-shadow: 0 4px 36px rgba(0, 0, 0, 0.7);
  line-height: normal;

  @media (max-width: $breakpoint-medium) {
    font-size: 32px;
  }
}

h2 {
  opacity: 0.4;
  font-weight: 500;
  font-size: 26px;
  color: #ffffff;
  letter-spacing: -0.4px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

  @media (max-width: $breakpoint-medium) {
    font-size: 20px;
  }
}

p {
  opacity: 0.4;
  font-size: 20px;
  color: #ffffff;
  line-height: 1.6em;

  @media (max-width: $breakpoint-medium) {
    font-size: 16px;
  }

  a {
    color: #ffffff;
  }
}

a {
  will-change: transform;
  transition: cubic-bezier(0.17, 0.67, 0.83, 0.67) 0.15s transform;

  img {
    max-width: unset;

    @media (max-width: $breakpoint-medium) {
      width: 80%;
      height: auto;
      margin: auto;
    }
  }
}

a:active {
  transform: scale(0.95);
}

.appstore-link {
  margin-top: 32px;
}

.wrapper {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(100px, auto);
  gap: 40px;

  @media (max-width: $breakpoint-medium) {
    gap: 20px;
  }
}

.full-width-container {
  position: relative;
}

.hero {
  grid-column: 1 / 3;
  backdrop-filter: blur(34px) saturate(2);

  .copy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: $breakpoint-medium) {
      margin-bottom: 20px;
    }

    .app-icon {
      margin-bottom: -35px;

      @media (max-width: $breakpoint-medium) {
        width: 160px;
        height: 160px;
      }
    }
  }
}

.full-width {
  grid-column: 1 / 3;
  height: 580px;
  overflow: hidden;

  @media (max-width: $breakpoint-medium) {
    height: unset;
    aspect-ratio: 1;
    background: #1b1b1b;
  }
}

.block {
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 29px 64px 40px rgba(0, 0, 0, 0.5);
  border-radius: 40px;
  padding: 100px;
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  transform: translate3d(0, 0, 0);
  overflow: hidden;

  @media (max-width: $breakpoint-medium) {
    padding: 24px;
    border-radius: 28px;
  }

  &.square {
    aspect-ratio: 1;

    @media (max-width: $breakpoint-medium) {
      grid-column: 1 / 3;
    }
  }

  &.hero {
    height: 800px;
    margin-top: 150px;
    justify-content: space-around;

    @media (max-width: $breakpoint-medium) {
      height: 750px;
      flex-direction: column;
      align-items: center;
      margin-top: 5%;
      justify-content: flex-start;
    }
  }

  .background {
    object-fit: cover;
    position: absolute;
    inset: 0;
    z-index: 5;
    min-height: 100%;
    object-position: center left;
  }

  .map {
    will-change: transform;
  }

  .icon {
    z-index: 10;
    position: absolute;
    top: calc(50% + 18px);
    left: 50%;
    transform: translate3d(-50%, -50%, 0);

    @media (max-width: $breakpoint-medium) {
      transform: translate3d(-50%, -50%, 0) scale(0.75);
    }
  }

  &#document {
    .lines {
      position: absolute;
      inset: 0;
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: center;
      justify-content: center;
      will-change: transform;

      @media (max-width: $breakpoint-medium) {
        transform: scale(0.75);
      }

      .line1 {
        margin-left: -280px;
      }

      .line3 {
        margin-left: 200px;
      }

      img {
        max-width: unset;
      }
    }
  }

  &.me {
    padding: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: unset;

    @media (max-width: $breakpoint-medium) {
      flex-direction: column;
      aspect-ratio: unset;
      gap: 20px;
      padding: 24px;
    }

    .avatar {
      border-radius: 50%;
      min-height: 100%;
      aspect-ratio: 1 / 1;
      width: auto;

      @media (max-width: $breakpoint-medium) {
        display: none;
      }
    }

    .left {
      display: flex;
      flex-direction: row;
      gap: 30px;
      flex: 1;

      .copy {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      flex-wrap: wrap;
      gap: 20px;

      @media (max-width: $breakpoint-medium) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 12px;
      }

      a img {
        @media (max-width: $breakpoint-medium) {
          margin: 0;
        }
      }
    }

    h3 {
      position: unset;
      margin-bottom: 8px;
      line-height: normal;
    }
    &:before {
      content: unset;
    }
  }

  h3 {
    position: absolute;
    bottom: 42px;
    text-align: center;
    font-weight: 600;
    font-size: 32px;
    z-index: 20;

    @media (max-width: $breakpoint-medium) {
      font-size: 20px;
      bottom: 24px;
    }
  }

  &:before {
    content: "";
    bottom: 0;
    left: 0;
    right: 0;
    height: 200px;
    background-image: linear-gradient(
      180deg,
      rgba(26, 26, 26, 0) 0%,
      #1a1a1a 100%
    );
    position: absolute;
    z-index: 10;
  }

  &:after {
    content: "";
    inset: 0;
    position: absolute;
    border-radius: 40px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.07);
    z-index: 20;
    pointer-events: none;

    @media (max-width: $breakpoint-medium) {
      border-radius: 28px;
    }
  }
}

.shutter {
  margin: 150px;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background: linear-gradient(180deg, #fcd200 0%, #f7a900 100%);
  @media (color-gamut: p3) {
    background: linear-gradient(
      180deg,
      color(display-p3 1 0.835 0) 0%,
      color(display-p3 0.969 0.663 0) 100%
    );
  }

  @media (max-width: $breakpoint-medium) {
    width: 80px;
    height: 80px;
    margin: 100px;
  }

  @media (max-width: $breakpoint-small) {
    width: 50px;
    height: 50px;
    margin: 60px;
  }
  position: relative;

  &:after {
    content: "";
    position: absolute;
    inset: -20px;
    border: 8px solid #f9bb00;
    opacity: 0.3;
    border-radius: 50%;

    @media (color-gamut: p3) {
      border: 8px solid color(display-p3 0.976 0.733 0);
    }

    @media (max-width: $breakpoint-medium) {
      inset: -12px;
      border-width: 6px;
    }

    @media (max-width: $breakpoint-small) {
      inset: -8px;
      border-width: 4px;
    }
  }

  .dash {
    height: 14px;
    width: 44px;
    border-radius: 7px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: linear-gradient(180deg, #fcd200 0%, #f7a900 100%);
    user-select: none;

    @media (color-gamut: p3) {
      background: linear-gradient(
        180deg,
        color(display-p3 1 0.835 0) 0%,
        color(display-p3 0.969 0.663 0) 100%
      );
    }

    @media (max-width: $breakpoint-medium) {
      height: 12px;
      width: 32px;
    }

    @media (max-width: $breakpoint-small) {
      height: 6px;
      width: 20px;
    }

    &.left {
      left: -100px;

      @media (max-width: $breakpoint-medium) {
        left: -75px;
      }

      @media (max-width: $breakpoint-small) {
        left: -40px;
      }
    }

    &.right {
      right: -100px;

      @media (max-width: $breakpoint-medium) {
        right: -75px;
      }

      @media (max-width: $breakpoint-small) {
        right: -40px;
      }
    }
  }
}

.wheels {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  .wheel {
    display: block;
    width: 750px;
    height: 750px;
    background: #2e2d2f;
    border-radius: 50%;
    will-change: transform;

    @media (max-width: $breakpoint-medium) {
      height: 625px;
      width: 625px;
    }

    @media (max-width: $breakpoint-small) {
      width: 500px;
      height: 500px;
    }

    &.right {
      span {
        justify-content: flex-start;
        transform-origin: 100%;
        padding-left: 30px;
        left: 0;

        @media (max-width: $breakpoint-medium) {
          padding-left: 25px;
        }

        @media (max-width: $breakpoint-small) {
          padding-left: 20px;
        }
      }

      @for $i from 1 through 9 {
        span:nth-child(2n + #{$i}) {
          transform: rotate($i * -22deg - 22deg + 180deg);

          @media (max-width: $breakpoint-medium) {
            transform: rotate($i * -20deg - 20deg + 180deg);
          }

          @media (max-width: $breakpoint-small) {
            transform: rotate($i * -18deg - 18deg + 180deg);
          }
        }
      }
    }

    span {
      font-family: ui-rounded, "SF Pro Rounded";
      font-weight: 600;
      text-align: right;
      transform-origin: 0;
      user-select: none;
      width: 50%;
      font-size: 50px;
      display: flex;
      position: absolute;
      height: 100%;
      justify-content: flex-end;
      align-items: center;
      left: 50%;
      padding-right: 30px;
      opacity: 0.4;

      @media (max-width: $breakpoint-medium) {
        font-size: 40px;
        padding-right: 25px;
      }

      @media (max-width: $breakpoint-small) {
        font-size: 30px;
        padding-right: 20px;
      }
    }

    @for $i from 1 through 9 {
      span:nth-child(2n + #{$i}) {
        transform: rotate($i * 22deg - 22deg);

        @media (max-width: $breakpoint-medium) {
          transform: rotate($i * 20deg - 20deg);
        }

        @media (max-width: $breakpoint-small) {
          transform: rotate($i * 18deg - 18deg);
        }
      }
    }
  }
}
